import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="container container-mobile grid-4 color-primary mb-7">
        <div className="d-flex flex-direction-v">
          <h4 className="mb-4 text-secondary font-bold">MEDICINSKA POMOĆ</h4>
          <a className="text-primary" href="/pocetna">
            Potporučnika Govedarice 12, Kragujevac 34000
          </a>
          <a className="text-primary" href="">
            medicinskapomockg@gmail.com
          </a>
          <a className="text-primary" href="">
            +381 66 574 5 000
          </a>
        </div>
        <div className="d-flex flex-direction-v">
          <h4 className="mb-4 text-secondary font-bold">POMOĆ</h4>
          <a className="text-primary" href="">
            Česta pitanja
          </a>
          <Link className="text-primary" to="/kontakt">
            Kontakt
          </Link>
          <Link className="text-primary" to="/pocetna#lokacija">
            Lokacija
          </Link>
          <Link className="text-primary" to="/pocetna#usluge">
            Usluge
          </Link>
          <Link className="text-primary" to="/kontakt">
            Zakazivanje termina
          </Link>
        </div>
        <div className="d-flex flex-direction-v">
          <h4 className="mb-4 text-secondary font-bold">DRUŠTVENE MREŽE</h4>
          <a className="text-primary" href="">
            Facebook
          </a>
          <a
            className="text-primary"
            href="https://www.instagram.com/medicinskapomoc/"
          >
            Instagram
          </a>
          <a className="text-primary" href="">
            LinkedIn
          </a>
        </div>
        <div className="d-flex flex-direction-v">
          <h4 className="mb-4 text-secondary font-bold">NOVOSTI</h4>
          <p className="text-primary">
            Za vreme pandemije virusa Covid 19, ambulatna Medicinska Pomoc ce
            raditi u periodu propisanim zakonom.
          </p>
        </div>
      </div>
      <p className="text-center text-primary mb-5">
        2020 Aleksa Svilkić &copy; alexa.unity@gmail.com{" "}
      </p>
    </footer>
  );
};

export default Footer;
