import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const scrollTo = (ref) => {
  if (ref) {
    ref.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export { scrollTo, ScrollToTop };
