import React, { useEffect } from "react";
// import { scrollTo } from "../components/scrollTo";

const IspiranjeKatetera = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // ref={scrollTo}
      className="container mb-5 p-3"
      style={{ lineHeight: "1.38rem" }}
    >
      <div className="d-flex flex-hc mb-5">
        <img style={{ maxWidth: "70px" }} src="../images/usluge/pomoc.png" />
      </div>
      <h1
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        ISPIRANJE KATETERA PACIJENTA
      </h1>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Ispiranje katetera
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
        ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis
        dis parturient montes, nascetur ridiculus mus. Donec quam felis,
        ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa
        quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget,
        arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
        Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras
        dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend
        tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac,
        enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Phasellus viverra nulla ut metus varius laoreet.
        <strong>Quisque rutrum. Aenean imperdiet.</strong> Etiam ultricies nisi
        vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam
        rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam
        semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc,
        blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et
        ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus.
        Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis
        leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna.
        Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis
        gravida magna mi a libero.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Sta je kateter i sta je ispiranje katetera?
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Fusce vulputate eleifend sapien. Vestibulum purus quam, scelerisque ut,
        mollis sed, nonummy id, metus. Nullam accumsan lorem in dui. Cras
        ultricies mi eu turpis hendrerit fringilla. Vestibulum ante ipsum primis
        in faucibus orci luctus et ultrices posuere cubilia Curae; In ac dui
        quis mi consectetuer lacinia. Nam pretium turpis et arcu. Duis arcu
        tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed
        aliquam ultrices mauris. Integer ante arcu, accumsan a, consectetuer
        eget, posuere ut, mauris. Praesent adipiscing. Phasellus ullamcorper
        ipsum rutrum nunc. Nunc nonummy metus. Vestibulum volutpat pretium
        libero. Cras id dui. Aenean ut eros et nisl sagittis vestibulum.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Kada je ispiranje neophodno?
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Nullam nulla eros, ultricies sit amet, nonummy id, imperdiet feugiat,
        pede. Sed lectus. Donec mollis hendrerit risus. Phasellus nec sem in
        justo pellentesque facilisis. Etiam imperdiet imperdiet orci. Nunc nec
        neque. Phasellus leo dolor, tempus non, auctor et, hendrerit quis, nisi.
        Curabitur ligula sapien, tincidunt non, euismod vitae, posuere
        imperdiet, leo. Maecenas malesuada. Praesent congue erat at massa. Sed
        cursus turpis vitae tortor. Donec posuere vulputate arcu. Phasellus
        accumsan cursus velit. Vestibulum ante ipsum primis in faucibus orci
        luctus et ultrices posuere cubilia Curae; Sed aliquam, nisi quis
        porttitor congue, elit erat euismod orci, ac placerat dolor lectus quis
        orci. Phasellus consectetuer vestibulum elit.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Zašto angažovati Ambulantu Medicinska Pomoć za ispiranje katetera?
      </h4>
      <p className="ml-2 mb-6 text-muted">
        Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc.
        Vestibulum fringilla pede sit amet augue. In turpis. Pellentesque
        posuere. Praesent turpis. Aenean posuere, tortor sed cursus feugiat,
        nunc augue blandit nunc, eu sollicitudin urna dolor sagittis lacus.
        Donec elit libero, sodales nec, volutpat a, suscipit non, turpis. Nullam
        sagittis. Suspendisse pulvinar, augue ac venenatis condimentum, sem
        libero volutpat nibh, nec pellentesque velit pede quis nunc. Vestibulum
        ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
        Curae; Fusce id purus. Ut varius tincidunt libero. Phasellus dolor.
        Maecenas vestibulum mollis diam. Pellentesque ut neque. Pellentesque
        habitant morbi tristique senectus et netus et malesuada fames ac turpis
        egestas. In dui magna, posuere eget, vestibulum et, tempor auctor,
        justo. In ac felis quis tortor malesuada pretium. Pellentesque auctor
        neque nec urna. Proin sapien ipsum, porta a, auctor quis, euismod ut,
        mi. Aenean viverra rhoncus pede. Pellentesque habitant morbi tristique
        senectus et netus et malesuada fames ac turpis egestas. Ut non enim
        eleifend felis pretium feugiat. Vivamus quis mi. Phasellus a est.
        Phasellus magna. In hac habitasse platea dictumst. Curabitur at lacus ac
        velit ornare lobortis. Curabitur a felis in nunc fringilla tristique.
      </p>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      />

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default IspiranjeKatetera;
