import React, { useState } from "react";
import { scrollTo } from "../components/scrollTo";

const Contact = ({ history }) => {
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const [formValues, setFormValues] = useState({
    ime: "",
    prezime: "",
    email: "",
    telefon: "",
    razlog: "",
    poruka: "",
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    console.log(formValues);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "kontakt", ...formValues }),
    })
      .then(() => alert("Uspešno poslata poruka!"))
      .catch((error) => alert(error));

    e.preventDefault();
  };

  return (
    <>
      {" "}
      <section ref={scrollTo} className="kontak">
        <div className="container">
          <h1 className="text-center mb-4">
            Kontaktirajte Ambulantu Medicinska Pomoc
          </h1>
          <h3 className="text-center mb-5">
            Imate potrebu da nas kontaktirate, možete to učiniti putem našeg
            formulara, a mi ćemo vam odgovoriti u najkracem mogućem roku.
          </h3>
          <div id="forma" className="d-grid">
            <div className="leftForm mobile-display-none"></div>
            <div className="rightForm bg-primary-gradient p-4">
              {/* <?php  
              if(isset($_POST['send']))
                  echo "<p className='text-center text-primary-light'>".$status."</p>"         
          ?> */}

              <form onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="kontakt" />
                {/* <!--Real Form--> */}
                <input
                  autoComplete="random"
                  className=""
                  type="text"
                  name="ime"
                  placeholder="Ime"
                  required
                  value={formValues.ime}
                  onChange={handleChange}
                />
                <input
                  autoComplete="random1"
                  className=""
                  type="text"
                  name="prezime"
                  placeholder="Prezime"
                  required
                  value={formValues.prezime}
                  onChange={handleChange}
                />
                <input
                  autoComplete="random2"
                  className=""
                  type="email"
                  name="email"
                  placeholder="Email Adresa"
                  required
                  value={formValues.email}
                  onChange={handleChange}
                />
                <input
                  autoComplete="random3"
                  type="text"
                  name="telefon"
                  placeholder="Vas telefon za kontakt"
                  value={formValues.telefon}
                  onChange={handleChange}
                />
                {/* <!--<input type="text" name="header" placeholder="Contact Reason" required/>--> */}
                <select
                  name="razlog"
                  onChange={handleChange}
                  value={formValues.razlog}
                >
                  <option value="" defaultValue>
                    Razlog kontakta
                  </option>
                  <option value="Zdravstveno pitanje">
                    Zdravstveno pitanje
                  </option>
                  <option value="Zakazivanje termina">
                    Zakazivanje termina
                  </option>
                  <option value="Vise pitanja">Vise pitanja</option>
                </select>
                <br />
                <textarea
                  className="w-80"
                  name="poruka"
                  cols="30"
                  rows="10"
                  placeholder="Tekst poruke."
                  value={formValues.poruka}
                  onChange={handleChange}
                ></textarea>

                <button type="submit">Posalji</button>
                {/* <script>
                      document.addEventListener("DOMContentLoaded", function(event)
                      {
                          setInterval(function(){ document.getElementById('honeyTime').value++; }, 1000);
                      });
                  </script> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
