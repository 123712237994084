import React from "react";

const Header = () => {
  return (
    <div id="headerUnset" className="m-center">
      <span
        className="text-center d-block text-second pb-6"
        style={{
          textDecoration: "underline",
          color: "#fff",
          cursor: "pointer",
        }}
        onClick={() => {
          const elemt = document.getElementById("sanitarni");
          if (elemt) elemt.scrollIntoView({ behavior: "smooth" });
        }}
      >
        <h3> - SANITETSKI PREVOZ KRAGUJEVAC - </h3>
      </span>
      <img
        className="logoSmall d-block m-center mb-5"
        src="images/logoSmall.png"
        alt="Medicinska Pomoc Banner"
      />
      <h1 className="text-center mb-5 text-default">
        AMBULANTA <strong className="font-secondary">MEDICINSKA POMOĆ</strong>
      </h1>
      <p className="text-center mb-5 m-center w-80 text-default">
        Ambulanta Medicinska Pomoc je pouzdana prijateljski nastrojena privatna
        ambulanta u Kragujevcu, koja pruza sirok spekar usluga od infuzija,
        previjanja, do kucne nege i sanitetskog prevoza. Fokus je uvek na
        pacijentima, pruzanju najboljih i najprofesionalnijih usluga uz vase
        zadovoljstvo.
      </p>
      <div className="d-flex flex-hc mb-7 text-default">
        <button className="pulse btn-third no-bg text-third">
          <a
            className="text-third"
            href="tel:+381 66 574 5000"
            title="Mobilni telefon Ambulante Medicinska Pomoc"
          >
            066-574-5000
          </a>
        </button>
        <button className="btn-third">
          <a href="kontakt" title="Kontakt">
            KONTAKTIRAJ NAS{" "}
          </a>
        </button>
      </div>
      <img
        className="m-center d-block svg"
        src="images/svg/arrowDown.svg"
        style={{ width: "45px", marginTop: "8rem" }}
      />
    </div>
  );
};

export default Header;
