import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        setTimeout(() => elem.scrollIntoView({ behavior: "smooth" }), 500);
      }
    }
  }, [location]);

  return (
    <>
      <section id="usluge" className="usluge">
        <div className="container container-mobile">
          <h2 className="font-secondary text-center mb-3">
            CENOVNIK I LISTA USLUGA
          </h2>
          <h2 className="text-center mb-7">
            Za vaše potrebe Medicinska Pomoc pruža sledece usluge
          </h2>
          <div className="grid-2 ">
            <div className="d-flex  flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/infuzije.png"
                  alt="Usluge Infuzije"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/infuzija"
                    title="Usluga infuzije Medicinska Pomoc"
                  >
                    INFUZIJE
                  </Link>
                </h3>{" "}
                <span className="cena"></span>
                {/* <!-- <p>Infuzija je tečni rastvor koji sadrži medicinski propisane vrednosti substanci koje su potrebne našem organizmu ukoliko se nalazi u abnormalnom stanju, kako bi se vratio u normalno. </p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/injekcije.png"
                  alt="Usluge Injekcije"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/injekcija"
                    title="Usluga injekcije Medicinska Pomoc"
                  >
                    INJEKCIJE
                  </Link>
                </h3>
                <span className="cena"></span>
                {/* <!-- <p>Injekcije se daju u različitim slučajevima, a najčešće kada je potrebno hitno delovanje, jer osećaj olakšanja sledi daleko brže nego kod drugih načina apliciranja leka.</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/previjanje.png"
                  alt="Usluge Previjanja"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/previjanje"
                    title="Usluga previjanja Medicinska Pomoc"
                  >
                    PREVIJANJE
                  </Link>
                </h3>
                <span className="cena"></span>
                {/* <!-- <p>Adekvatna i redovna nega i previjanje rane sprečiće infekcije, koje mogu usporiti zarastanje rane i biti posebno opasne, pa čak i po život pacijenta.</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/stoma.png"
                  alt="Usluge Nege Stome"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/negastome"
                    title="Usluga nege stome Medicinska Pomoc"
                  >
                    NEGA STOME
                  </Link>
                </h3>
                <span className="cena"></span>
                {/* <!-- <p>Nega stome, zamena diska i kese i nega oštećene kože.</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/kucnanega.png"
                  alt="Kućna nega"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/kucnanega"
                    title="Usluga kucne nege Medicinska Pomoc"
                  >
                    KUĆNA NEGA
                  </Link>
                </h3>
                <span className="cena"></span>
                {/* <!-- <p>Kucna nega obuhvata dolazak sestre na adresu radi zbrinjavanja nepokretnih i slabo pokretnih lica (odrzavanje higijene,prevencija dekubita...)</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/klizma.png"
                  alt="Usluga klizme"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/klizma"
                    title="Usluga klizma Medicinska Pomoc"
                  >
                    KLIZMA
                  </Link>
                </h3>
                <span className="cena"></span>
                {/* <!-- <p>Klizma je terapijska procedura koja podrazumeva ubrizgavanje tecnosti kroz analni otvor u debelo crevo radi njegovog ciscenja i praznjenja</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/pomoc.png"
                  alt="Usluga kupanja"
                />
              </div>
              <div>
                <h3 className="font-secondary">KUPANJE</h3>
                <span className="cena"></span>
                {/* <!-- <h3 className="font-secondary"><a className="text-primary" style="font-size:18px" href="kupanje">KUPANJE</a></h3><span className="cena"></span> --> */}
                {/* <!-- <p>Kupanje i pomoc oko kupanja za nepokretne i slabo pokretne!</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/kateter.png"
                  alt="Usluga Plasiranje i zamena katetera"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/zamenakatetera"
                    title="Usluga plasiranje i zamena katetera Medicinska Pomoc"
                  >
                    PLASIRANJE I ZAMENA KATETERA
                  </Link>
                </h3>
                <span className="cena"></span>
                {/* <!-- <p>Plasiranje i zamena katetera-kateterizacija je plasiranje katetera u mokracnu besiku radi praznjenja sadrzaja iz besike.Vrsi se zbog retencije urina (zastoj urina)ili inkontinencije(bezvoljno mokrenje).</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/uzorkovanjeKrvi.png"
                  alt="Analiza Krvi Kraguejvac"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/uzorkovanjekrvi"
                    title="Usluga uzimanja krvi Medicinska Pomoc"
                  >
                    UZORKOVANJE KRVI ZA ANALIZE
                  </Link>
                </h3>
                <span className="cena"></span>
                {/* <!-- <p>Dolazak na kućnu adresu, vadjenje krvi, uzimanje briseva i urina.</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/dezurstvo.png"
                  alt="Usluga Dežurstvo pored pacijenta"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/dezurstvoporedpacijenta"
                    title="Usluga dezurstva pored pacijenta Medicinska Pomoc"
                  >
                    DEŽURSTVO PORED PACIJENTA
                  </Link>
                </h3>
                <span className="cena"></span>
                {/* <!-- <p>Dežurstvo pored pacijenta podrazumeva prisustvo sestre i zbrinjavanje pacijenta od 00:00-24:00h po potrebi.</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/pomockuce.png"
                  alt="Uslugea Pomoć u kući"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/pomocukuci"
                    title="Usluga pomoc u kuci Medicinska Pomoc"
                  >
                    POMOĆ U KUĆI
                  </Link>
                </h3>
                <span className="cena"></span>
                {/* <!-- <p>Sređivanje i higijena kuće, odlazak u kupovinu i po lekove, spremanje obroka.</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/ispiranjeUsiju.png"
                  alt="Usluge Ispiranja Usiju"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/ispiranjeusiju"
                    title="Usluga ispiranje usiju Medicinska Pomoc"
                  >
                    ISPIRANJE USIJU
                  </Link>
                </h3>
                <span className="cena"></span>
                {/* <!-- <p>Ispiranje ušiju je medicinski postupak kojim se sa tečnošću ispira sadržaj spoljašnjeg uva.</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/pomoc.png"
                  alt="Usluga Ispiranje Katetera"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/ispiranjekatetera"
                    title="Usluga ispiranje katetera Medicinska Pomoc"
                  >
                    ISPIRANJE KATETERA
                  </Link>
                </h3>
                <span className="cena"></span>
                {/* <!-- <p>Ispiranje katetera podrazumeva održavanje prohodnosti katetera.</p> --> */}
              </div>
            </div>
            <div className="d-flex flex-vc">
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/pomoc.png"
                  alt="Usluge Medicinkse pomoci"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  SVE USLUGE U AMBULANTI I NA TERENU
                </h3>
                {/* <!-- <p>Vršimo sve usluge ambulante na terenu, pozovite ili kontaktirajte za jos informacija.</p> --> */}
              </div>
            </div>
            <div
              id="prevoz"
              className="d-flex flex-vc flex-direction-v text-center"
            >
              <div className="pr-3">
                <img
                  className="uslugaImg"
                  src="images/usluge/sanitarni.png"
                  alt="Usluga Ispiranje Katetera"
                />
              </div>
              <div>
                <h3 className="font-secondary">
                  <Link
                    className="text-primary"
                    style={{ fontSize: "18px" }}
                    to="/sanitet"
                    title="Usluga sanitetski prevoz Medicinska Pomoc"
                  >
                    SANITETSKI PREVOZ
                  </Link>
                </h3>
                <a className="cena" href="#sanitarni">
                  Kliknite za više informacija
                </a>
                {/* <!-- <p>Prevoz nepokretnih i slabo pokretnih lica u zemlji i inostranstvu.</p> --> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="onama"
        style={{ backgroundColor: "#dedede", margin: "0", padding: "5rem" }}
        className="onama"
      >
        <div className="container container-mobile text-center">
          <h2 className="font-secondary text-center mb-3">Ukratko o nama</h2>
          <p className="mb-3">Budite odgovorni prema svom zdravlju!</p>
          <p className="mb-3">
            Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
            zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave
            prvi simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji
            svih bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
          </p>
          <p className="mb-3">
            Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
            obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
            bogatih obroka, pratimo evropska dostignuća u svim oblastima
            medicine i specijalističkih grana i držimo visok standard u pružanju
            usluga.{" "}
          </p>
          <p className="mb-3">
            Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u
            pružanju zdravstvenih usluga i nege u vašem domu bazične su
            preporuke naše medicinske ambulante. Sve je građeno na velikom
            međusobnom poverenju jer naš tim postaje deo vaše porodice!
          </p>
          <p className="mb-3">
            Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
            pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko
            vam je potrebna psihološka podrška, konsultovaćemo se s našim
            vrhunskim psiholozima i psihijatrima i uneti malo pozitivnog duha u
            vaš život!
          </p>
          <p className="">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
        </div>
      </section>

      <section className="vremeprevoz d-flex">
        <div className="left w-40 d-flex ">
          <img
            className="medicinskaRuka"
            src="images/medicinskeRuke.webp"
            alt="Medicske Ruke"
          />
          <div className="d-flex flex-direction-v w-100 flex-hc flex-vc p-5 pm-0">
            <h2 className="text-default mb-2">Radno vreme</h2>
            {/* <!--
                    <p className="text-default mb-2">Ponedeljak - Petak 07:00h - 17:00h</p>
                    <p className="text-default mb-2">Subota 07:00h - 15:00h</p>
                    <p className="text-default mb-2">Nedeljom ne radimo</p>
                    <p className="text-default mb-2">Za hitne slučajeve pozvati</p>
                --> */}
            <ul className="listVreme w-100 flex-direction-v">
              <li className="d-flex w-80 m-center pb-1">
                <span>PONEDELJAK</span> <span>07:00 - 19:00</span>
              </li>
              <li className="d-flex w-80 m-center pb-1">
                <span>UTORAK</span> <span>07:00 - 19:00</span>
              </li>
              <li className="d-flex w-80 m-center pb-1">
                <span>SREDA</span> <span>07:00 - 19:00</span>
              </li>
              <li className="d-flex w-80 m-center pb-1">
                <span>ČETVRTAK</span> <span>07:00 - 19:00</span>
              </li>
              <li className="d-flex w-80 m-center pb-1">
                <span>PETAK</span> <span>07:00 - 19:00</span>
              </li>
              <li className="d-flex w-80 m-center pb-1">
                <span>SUBOTA</span> <span>POZVATI</span>
              </li>
              <li className="d-flex w-80 m-center pb-1">
                <span>NEDELJA</span> <span>POZVATI</span>
              </li>
            </ul>
            <p className="text-center text-default mb-5">
              POSLE RADNOG VREMENA, POZVATI I ZAKAZATI TERMINE.
            </p>
            <Link
              className="w-60"
              to="/kontakt"
              title="Kontakt Ambulanta Medicinska Pomoc"
            >
              <button className="w-100 bg-third text-default"> KONTAKT </button>{" "}
            </Link>
          </div>
        </div>
        <div id="sanitarni" className="right w-60 bg-primary-light p-5 pmt-2">
          <div className="container-mobile d-flex ">
            <div className="ambulanceCar">
              <img src="images/sanitarniPrevoz.webp" alt="Sanitarni prevoz" />
            </div>
            <div className="ambulanceCarText d-flex flex-direction-v flex-hc flex-vc">
              <h2 className="mb-2 text-primary-black">SANITETSKI PREVOZ</h2>
              <h4 className="mb-5 text-primary-darker">
                NEPOKRETNIH I SLABOPOKRETNIH OSOBA
              </h4>
              <p className="mb-4 text-primary-regular">
                Prevoz pacijenta je tezak kao za pacijenta tako i za porodicu.
                Mi pruzamo brizne usluge i uvek smo voljni da pomognemo koliko
                mozemo. Na hitne pozive transporta odgovaramo munjevito. Termini
                se mogu za sve unapred zakazati.
              </p>
              <div className="w-100" style={{ textAlign: "left" }}>
                <p className="text-primary-darker">
                  SANITSKI PREVOZ - dalje relacije{" "}
                  <span className="cena">  </span>
                </p>
                <p className="text-primary-darker">
                  SANITSKI PREVOZ - inostrani prevoz{" "}
                  <span className="cena">  </span>
                </p>
                <p className="text-primary-darker">
                  SANITET PREVOZ jedan pravac - BOLNICA{" "}
                  <span className="cena">  </span>
                </p>
                <p className="text-primary-darker">
                  SANITSKI PREVOZ - kompletna usluga{" "}
                  <span className="cena"> </span>
                </p>
              </div>
              {/* <!-- 
                       <div className="d-flex "> 
                        <button className="bg-third text-default">POZOVITE +381 66 574 5 000</button>
                        <button className="bg-third text-default">KONTAKT PUTEM SAJTA</button>
                       </div>
                    -->  */}
            </div>
          </div>
        </div>
      </section>

      <section id="lokacija">
        <div className="container container-mobile">
          <div>
            <h2 className="text-center mb-5">KAKO DO NAS</h2>
            <p className="text-center mb-5">
              Privtna Ambulanta Medicinska Pomoc je u Kragujevcu, u neposrednoj
              blizini glavne bolnice Krgaujevca, u ulici Potporucnika
              Govedarice. Pored samog puta, tesko je promasiti, lokal je jasno
              vidljiv, a tu je i mogucnost pronalazenja preko google mapa.
              Ukoliko imate poteskoca u snalazenju, slobodno nas kontaktirajte.
            </p>
            <ul
              className="grid-4 mb-5 flex-mobile"
              style={{ justifyContent: "space-around" }}
            >
              <li className="text-third" style={{ gridColumn: "1/3" }}>
                {" "}
                Potporučnika Govedarice 12, Kragujevac 34000
              </li>
              <li className="text-third">
                <a
                  className="text-third"
                  href="tel:+381665745000"
                  style={{ fontSize: "inherit" }}
                  title="Kontakt telefon Ambulante Medicinska Pomoc"
                >
                  +381 66 574 5 000
                </a>
              </li>
              <li className="text-third">
                <a
                  className="text-third"
                  href="mailto:medicinskapomockg@gmail.com"
                  style={{ fontSize: "inherit" }}
                  title="Email adresa Ambulante Medicinska Pomoc"
                >
                  medicinskapomockg@gmail.com
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-100 mb-6">
          <iframe
            className="w-80 m-center d-flex p-4"
            height="500"
            src="https://maps.google.com/maps?q=potporucnika%20govedarice%2012&t=&z=17&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            style={{ border: "2px solid lightgray" }}
          ></iframe>
        </div>
        {/* <!--<div className="container d-flex flex-vc">
            <p className="mr-6">Želiš da zakažeš termin ili da nas kontaktiraš</p> <button className=" w-20 bg-third text-default">KONTAKT</button>
        </div>     
        --> */}
      </section>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      ></div>
    </>
  );
};

export default Home;
