import React, { useEffect } from "react";
// import { scrollTo } from "../components/scrollTo";

const Injekcija = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // ref={scrollTo}
      className="container mb-5 p-3"
      style={{ lineHeight: "1.38rem" }}
    >
      <div className="d-flex flex-hc mb-5">
        <img
          style={{ maxWidth: "80px" }}
          src="../images/usluge/injekcije.png"
        />
      </div>
      <h1
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        Davanje injekcija u kući ili ambulanti
      </h1>

      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Davanje injekcija
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Injekcije predstavljaju najbržih i najpouzdanijih načina da pacijent
        dobije propisani lek u tečnom stanju, vrlo često hitno ili vreme koje
        određuje izabrani lekar. Davanje injekcije je nekad presudno za
        zdravlje, pa i život pacijenta, a ceo proces podrazumeva korišćenje
        sterilnog pribora, igle i šprica. Naše stručno medicinsko osoblje
        garantuje profesionalno i bezbolno davanje injekcija i u ambulanti i u
        kućnim uslovima, na adresi koju naznačite u svojim zahtevima. Davanje
        terapije u tačno određeno vreme prepustite nama, jer Medicinska pomoć
        Kragujevac vodiće vaš lični kalendar terapije.{" "}
      </p>
      <p className="ml-2 mb-5 text-muted">
        Kada se opredelite da vam naša medicinska sestra da injekciju, ona će
        prvo dezinfikovati mesto na koži i bezbolno dati injekciju. Nakon toga
        lekovi se automatski resorbuju u krvotok pacijenta, kada je nužno da oni
        deluju što brže ili na tačno određeno mesto. Apliciranje leka pomoću
        injekcije preporučljivo je i u slučajevima kad je potrebno izbeći
        rastvaranje leka želudačnom kiselinom i kada pacijent nije u stanju da
        guta niti na usta unosi terapiju u obliku tableta.
      </p>
      <p className="ml-2 mb-5 text-muted">
        U zavisnosti od problema i stanja pacijenta određuje se i vrsta
        injekcije.
      </p>

      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Vrste injekcija
      </h4>
      <p className="ml-2  text-muted">
        Prema mestu na koje se ubrizgavaju, razlikuje se nekoliko vrsta
        injekcija:
      </p>
      <ul className="text-muted d-block mb-4">
        <li>•kožne (intradermalne)</li>
        <li>•potkožne (supkutane)</li>
        <li>•mišićne (intramuskulame)</li>
        <li>•intravenozne</li>
        <li>•intraarterijske</li>
        <li>•intralumbalne</li>
        <li>•druge vrste injekcija</li>
      </ul>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      ></div>

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default Injekcija;
