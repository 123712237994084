import React, { useState } from "react";
import Header from "./header";
import { useRouteMatch, Link } from "react-router-dom";

const Navbar = () => {
  const home = useRouteMatch("/pocetna");
  const [open, setOpen] = useState(false);

  const getClass = () => {
    let classes = "header ";
    if (!home) classes += " low ";
    return classes;
  };
  const getMobile = () => {
    if (open === true) return { display: "flex" };
    else return { display: "" };
  };

  return (
    <section className={getClass()}>
      <div className="container h-100 d-flex flex-direction-v">
        <div id="showNavbar" className={open ? "bg-darker p-3" : "p-3"}>
          <a
            className="navbar-mobile-button svg"
            onClick={() => setOpen(!open)}
          >
            <img src="images/svg/navButton.svg" alt="navButton" />
          </a>
        </div>

        <div className="navbar mb-7" style={getMobile()}>
          <ul className="navbar-nav">
            <li className="navbar-link">
              <img
                className="navLogo"
                src="images/logo.png"
                alt="Ambulanta Medicinska Pomoc"
              />
            </li>
            <li className="navbar-link">
              <Link
                to="/pocetna"
                title="Pocetna strana Ambulante Medicinska Pomoc"
                onClick={() => setOpen(false)}
              >
                POČETNA
              </Link>
            </li>
            <li className="navbar-link">
              <Link
                to="/pocetna#usluge"
                title="Usluge Ambulante Medicinska Pomoc"
                onClick={() => {
                  const elemt = document.getElementById("usluge");
                  if (elemt) elemt.scrollIntoView({ behavior: "smooth" });
                  setOpen(false);
                }}
              >
                USLUGE
              </Link>
            </li>
            {/* <li className="navbar-link"><a href="onama.php"> O NAMA </a> </li>  */}
            <li className="navbar-link">
              <Link
                to="/kontakt"
                title="Kontakt Ambulante Medicinska Pomoc"
                onClick={() => setOpen(false)}
              >
                KONTAKT
              </Link>
            </li>
          </ul>
        </div>

        {home && <Header />}
      </div>
    </section>
  );
};

export default Navbar;
