import React, { useEffect } from "react";
// import { scrollTo } from "../components/scrollTo";

const Infuzija = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // ref={scrollTo}
      className="container mb-5 p-3"
      style={{ lineHeight: "1.38rem" }}
    >
      <div className="d-flex flex-hc mb-5">
        <img style={{ maxWidth: "80px" }} src="../images/usluge/infuzije.png" />
      </div>
      <h1
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        Davanje infuzija u kući i ambulanti
      </h1>

      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Sta je infuzija?
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Infuzija - tečni rastvor koji sadrži osnovne supstance koje su potrebne
        organizmu: soli, glukozu, elektrolite.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Infuzija predstavlja sporo i ravnomerno unošenje aktivnog fiziološkog
        rastvora određene materije u organizam. Omogućava umereno ubacivanje
        neophodnih sastojaka direktno u krvotok tokom dužeg vremena, kada je
        potrebno uneti veliku količinu tečnosti u organizam i nadokanditi
        elektrolite.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Infuzija se često koristi i kao osnova pomoću koje se lek ubacuje u
        infuzioni rastvor, a zatim i u oslabljeni organizam. Ovim postupkom lek
        brže dospeva u krvotok, pa je i njegovo dejstvo brže i delotvornije.
      </p>

      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Davanje infuzija
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Daje se intravenozno (ubruzgavanjem u venu), kod stanja kada je telo
        naglo izgubilo preveliku količinu tečnosti. Infuzija na taj način vraća
        izgubljenu tečnost u organizam, nadoknađuje izgubljenu snagu i čini da
        se osećamo bolje.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Infuzija se prepisuje kao hitna pomoć kod različitih stanja bolesnih,
        ali i zdravih osoba. Pacijent oseća umanjenje tegoba u najbržem mogućem
        roku, a njegov organizam se snabdeva materijama koje su mu preko
        potrebne.
      </p>

      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Kada je davanje infuzija neophodno?
      </h4>
      <p className="ml-2  text-muted">
        Davanje infuzije je preporučljivo i neophodno u sledećim situacijama:
      </p>
      <ul className="text-muted d-block mb-4">
        <li>•lečenje hroničnog umora usled iscrpljenosti i stresa;</li>
        <li>•problemi sa sistemom za varenje (diareje);</li>
        <li>•mamurluk (nakon neumerenog korišenja alkohola);</li>
        <li>•uporna temperatura koja se dugo ne skida</li>
        <li>•nadoknada vanćelijske tečnosti;</li>
        <li>•detoksikacija;</li>
        <li>•usled fizičkih napora;</li>
        <li>•usled akutne, hronične ili bakterijske infekcije;</li>
        <li>•dugotrajno izlaganje visokim temperaturama;</li>
        <li>•nakon opekotina;</li>
        <li>•oslabljen imunitet.</li>
      </ul>

      <p className="ml-2 mb-6 text-muted">
        Davanje infuzije mora obaviti stručno medicinsko osoblje zato što
        infuzija dolazi u direktan kontakt sa krvotokom i može doći do raznih
        komplikacija usled nestručnog davanja infuzije. Zato je Medicinska pomoć
        Kragujevac garancija kvalitetne i profesionalne usluge za sve naše
        pacijente.
      </p>
      <p className="ml-2 mb-6 text-muted">
        Infuziju lekar prepisuje u situacijama kada postoji potreba za unošenjem
        velike količine tečnosti u organizam, kao i u situacijama kada su
        organizmu potrebne hranljive materije, kod primene nekih lekova,
        poremećaja krvi...
      </p>

      <p className="ml-2 mb-6 text-muted">
        Infuzija može da se primi u ambulanti ili kod kuće. Zbog lošeg stanja u
        kom se nalaze osobe kojima je neophodna infuzija, često je infuziju
        preporučljivo davati u kućnim uslovima. Naš tim je u svakom trenutku na
        raspolaganju i izlazi na teren u najkraćem roku od vašeg poziva.{" "}
      </p>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      ></div>

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default Infuzija;
