import React, { useEffect } from "react";

const Sanitet = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container mb-5 p-3" style={{ lineHeight: "1.38rem" }}>
      <div className="d-flex flex-hc mb-5">
        <img
          style={{ maxWidth: "80px" }}
          src="../images/usluge/sanitarni.svg"
        />
      </div>
      <h1
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        SANITETSKI PREVOZ KRAGUJEVAC
      </h1>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Sanitetski prevoz
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Odgovarajući na potrebe naših pacijenata kojima je ova usluga bila
        neophodna, Medicinska pomoć Kragujevac opredelila se za bogaćenje naše
        ponude – prevoz sanitetom po sistemu „od kreveta do kreveta“. Ovaj vid
        transporta je osmišljen da vam pomogne da stignete od kuće do medicinske
        ustanove, i obrnuto, u najkraćem mogućem roku, uz poštovanje svih
        medicinskih i saobraćajnih normi. Vozimo vas od vašeg prebivališta do
        željene adrese ili odredišta, i nazad. Ova usluga namenjena je svima
        koji imaju potrebu za profesionalnim prevozom uz medicinsku negu tokom
        transporta, a prvenstveno se odnosi na naše nepokretne i slabo pokretne
        korisnike i njihove pratioce.{" "}
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Sta obuhvata sanitetski prevoz
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Vozilo Medicinske pomoći Kragujevac opremljeno je vrhunskom medicinskom
        opremom za sve vrste ležećih ili sedećih pacijenata, dece i odraslih. Za
        tu vrstu usluge opremljeni smo u potpunosti u skladu sa svim zakonskim
        propisima koji važe na teritoriji Republike Srbije, regiona i Evropske
        unije. Prevozimo nepokretne i teško pokretne osobe i osobe sa
        invaliditetom do zdravstvenih ustanova, banjskih lečilišta, domova za
        stare, željenih izletišta, planinskih centara ili na različita veselja,
        svadbe, krštenja i druge svetkovine i slavlja koja nikako ne želite da
        propustite sa svojim najmilijima. Organizovaćemo vam i posetu
        prijateljima ukoliko je to vaša želja. Na toj listi odredišta nalaze se
        i aerodromi, železničke i autobuske stanice. Ono što je najbitnije, naš
        medicinski obučen i profesionalni tim u svakom trenutku tokom puta biće
        vam podrška i potpora. Po potrebi, sačekaćemo naše pacijente dok su na
        pregledu, dijagnostici, terapiji, u obilasku, poseti ili na izletu.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Kada je potreban sanitetski prevoz?
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Prevoz pacijenata Medicinska pomoć Kragujevac vrši moderno opremljenim i
        udobnim ambulantnim kolima uz prisustvo medicinskog tehničara ili lekara
        koji je pacijentu na raspolaganju sve vreme tokom transporta. Vozilo
        redovno održavamo u ovlašćenom servisu, dva puta godišnje podvrgavamo ga
        teničkom pregledu i strogo vodimo računa o higijeni kako vozila, tako i
        opreme, a posebno o higijeni specijalne posteljine za naše pacijente.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Zašto angažovati Ambulantu Medicinska Pomoć za sanitetski prevoz?
      </h4>
      <p className="ml-2 mb-6 text-muted">
        Medicinska pomoć Kragujevac transport sanitetskim vozilom obavlja na
        teritoriji cele Srbije i, naglašavamo, bilo gde u inostranstvu, u
        zemljama članicama Evropske unije i van nje. U svako doba dana i noći,
        tokom cele nedelje i tokom cele godine možete se osloniti na naš brzi i
        pouzdan odgovor na vaše potrebe. S našim timom možete napraviti bilo
        kakav dogovor koji vam odgovara, vaš zahtev za nas je prioritet.
        Učinićemo sve da što pre stignete do svoje destinacije, a mi ćemo sve
        vreme biti posvećeni vašem zdravlju i vašoj bezbednosti. Ova specifična,
        profesionalna usluga može se prilagoditi svim vašim željama i potrebama,
        možete birati da li ćete je koristiti samo sa vozačem, sa vozačem i
        našom medicinskom pratnjom, sa vozačem i vašom medicinskom pratnjom, sa
        vozačem i vašim članom porodice ili prijateljem. Otvoreni smo za svaki
        dogovor s vama.
      </p>
      <p className="ml-2 text-muted">
        Sve naše usluge možete rezervisati na telefon 066/574-5000 uz
        najpovoljnije cene.{" "}
      </p>
      <p className="ml-2 mb-5 text-muted">
        Vaša Medicinska pomoć Kragujevac, uvek s vama i svuda!
      </p>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      ></div>

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default Sanitet;
