import React, { useEffect } from "react";
// import { scrollTo } from "../components/scrollTo";

const Klizma = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // ref={scrollTo}
      className="container mb-5 p-3"
      style={{ lineHeight: "1.38rem" }}
    >
      <div className="d-flex flex-hc mb-5">
        <img style={{ maxWidth: "70px" }} src="../images/usluge/klizma.png" />
      </div>
      <h1
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        USLUGA KLIZME
      </h1>

      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Klizma
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Klistiranje je često potrebno pacijentima koji su nedavno operisani ili
        duže vreme provode u postelji, a pate od opstipacije (zastoja u radu
        creva). I zdravim sugrađanima, trudnicama i porodiljama ponekad je
        potrebna intervencija koja će trenutno dovesti do olakšanja i ublažiti
        tegobe. Naša Medicinska pomoć Kragujevac svakom korisniku rešiće problem
        sa stolicom ili očisti creva ukoliko ima potrebe za tim, a posebno kao
        deo pripreme za endoskopski pregled debelog creva i sličnih
        dijagnostičkih procedura.
      </p>

      <p className="ml-2 mb-5 text-muted">
        Klizma ili klistiranje debelog creva je terapijska procedura koja
        podrazumeva ubrizgavanje tečnosti kroz analni otvor u debelo crevo radi
        njegovog čišćenja i pražnjenja, postavljanja diagnoze ili davanja
        lekova. Klizma se radi isključivo kada je indikuje lekar, a izvode je
        medicinski obučene osobe koje su godinama sticale iskustvo na našim
        najpoznatijim klinikama i poseduju rutinu u njenom izvođenju. Postupak
        mora biti pravilno i precizno izveden kako bi se izbegli bol i
        neprijatnost, što je primarni cilj naše ambulante.{" "}
      </p>

      <p className="ml-2 mb-5 text-muted">
        Klizmu obavlja stručna osoba tako što pomoću sterilnog analnog katetera
        u debelo crevo ulije tečnost koja se sastoji od vode i nekog od sledećih
        sastojaka: sapunice, parafinskog ulja, glicerola, čaja od kamilice,
        fiziološkog rastvora. Na ovaj način se izazivaju nadražaji u crevu, što
        za posledicu ima izbacivanje sadržaja
      </p>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      ></div>

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default Klizma;
