import React, { useEffect } from "react";
// import { scrollTo } from "../components/scrollTo";

const NegaStome = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // ref={scrollTo}
      className="container mb-5 p-3"
      style={{ lineHeight: "1.38rem" }}
    >
      <div className="d-flex flex-hc mb-5">
        <img style={{ maxWidth: "70px" }} src="../images/usluge/stoma.png" />
      </div>
      <h1
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        NEGA STOME
      </h1>

      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Nega stome pacijenta
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Stoma je hirurški izveden otvor koji vodi od unutrašnjosti organa do
        spoljašnjosti. Naša ambulanta Medicinska pomoć Kragujevac pruža vam
        usluge izvođenja i nege stome, gde struka i mi udruženim snagama uvek
        imamo u vidu različitost vaših životnih potreba. Od izuzetne je važnosti
        zbrinuti vašu stomu na adekvatan način.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Stoma je zaobilazni put kojim se otpadne materije izlučuju iz vašeg
        organizma. Mesto postavljanja stome zavisi od područja na kojem je došlo
        do oštećenja vašeg organa i od vrste hirurškog zahvata koji je izveden.
      </p>

      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Sve o stomi
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Stoma predstavlja veštački otvor na telu na kome je deo creva pričvršćen
        za kožu. Kesa za stomu se potom pričvrsti na specijalan disk povezan sa
        otvorom (lepak kojim se lepi disk je vodootporan), i to u slučaju
        kolonostomije, ileostomije i urostomije, na taj način da se fekalije ili
        urin odvode u ovu kesu.{" "}
      </p>

      <p className="ml-3 mb-2 text-muted">
        <strong>Kolonostomija</strong> ̶ posle operacije u kojoj se debelo crevo
        premešta uz trbušni zid slede otvaranje i pričvršćivanje debelog creva
        na abdominalni zid, nakon čega fekalije zaobilaze analni otvor.
      </p>
      <p className="ml-3 mb-2 text-muted">
        <strong>Ileostomija</strong> – otvaranje i premošćavanje od tankog creva
        do abdominalnog zida, koji se otvara, nakon čega fekalije zaobilaze
        debelo crevo i analni kanal.{" "}
      </p>
      <p className="ml-3 mb-2 text-muted">
        <strong>Urostomija</strong> ̶ povezuje urinarni trakt i abdominalni zid
        tako da direktno vodi iz bubrega, nakon čega urin prolazi pravo u kesu
        stome i zaobilazi bešiku. Obično se koriste nakon invazivne neoplazije
        bešike ili prostate.
      </p>
      <p className="ml-3 mb-4 text-muted">
        <strong>Gastrostomija</strong> ̶ izvodi se kad je potrebno povezati
        želudac i abdominalni zid, najčešće u slučaju postavljanja enteralne
        hranljive cevi. Koristi se kod različitih poremećaja: inflamatorna
        bolest creva, neoplazija i divertikularna bolest.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Stome mogu biti privremene ili trajne. Privremene stome se posle
        određenog vremena zatvaraju, kada se problem nastao na unutrašnjim
        organima reši. Ukoliko je vaš doktor specijalista morao da izvede
        hirurški zahvat, razlog je uvek medicinsko stanje koje je posledica
        bolesti ili povrede, čime je onemogućen pravilan rad vašeg probavnog ili
        mokraćnog sistema. Hirurg će odstraniti bolesni ili povređeni deo
        probavnog ili mokraćnog sistema i izvesti stomu. Izvođenje stome ima za
        cilj da unapredi vaše postojeće zdravstveno stanje, da smanji bol i
        patnju, eliminiše bolest. Stoma se izvodi samo onda kada je vaš probavni
        ili mokraćni sistem toliko oštećen bolešću da se ne može oporaviti.
        Tokom ovog osetljivog trenutka vašeg zdravstvenog stanja nećete biti
        sami. Medicinska pomoć Kragujevac pomoći će vam da uz stručan i brižan
        nadzor imate što manje tegoba i neprijatnosti.
      </p>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      ></div>

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default NegaStome;
