import React, { useEffect } from "react";
// import { scrollTo } from "../components/scrollTo";

const UzorkovanjeKrvi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // ref={scrollTo}
      className="container mb-5 p-3"
      style={{ lineHeight: "1.38rem" }}
    >
      <div className="d-flex flex-hc mb-5">
        <img
          style={{ maxWidth: "80px" }}
          src="../images/usluge/uzorkovanjeKrvi.svg"
        />
      </div>
      <h1
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        UZORKOVANJE KRVI I URINA ZA ANALIZU
      </h1>
      <h2
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        OPŠTI PREGLED PACIJENTA U AMBULANTI ILI NA TERENU
      </h2>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Uzorkovanje krvi
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Prepustite se sigurnim rukama naših medicinskih tehničara za uzimanje
        krvi na analizu, budite opušteni i bezbedni jer koristimo isključivo
        sterilan pribor za uzorkovanje, visokog kvaliteta. Ukoliko paničite i
        imate neracionalan strah od vađenja krvi, naše višegodišnje iskustvo u
        pružanju ove vrste usluga uveriće vas da jedna nelagodan i neprijatan
        doživljaj za vas može lako da postane igrarija.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Naši saradnici su poznate referentne laboratorije na teritoriji grada
        Kragujevca i cele Srbije, sa svim potrebnim sertifikatima za obavljanje
        ove delatnosti.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Medicinska pomoć Kragujevac po pozivu organizuje na terenu stručnu pomoć
        pacijentima, 24 sata dnevno, sedam dana u nedelji. Naš medicinski tim
        uvek je na raspolaganju da vam pomogne u ambulantnim uslovima i u kućnim
        posetama. Pregled na terenu obuhvata uzimanje uzoraka krvi i urina za
        analizu i njihovo tumačenje, pružanje prve pomoći, merenje krvnog
        pritiska i pulsa, EKG, kontrola šećera, davanje infuzije i terapije
        bola.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Priprema pacijenta za davanje uzorka krvi
      </h4>
      <p className="ml-2 text-muted">
        Da bi laboratorijski rezultati naših pacijenata bili pouzdani, potrebno
        je da se pripremite i uputite u procedure pre davanja uzorka krvi na
        analizu. To ćete postići ako se pridržavate sledećih pravila kod
        priprema za davanje uzorka krvi:
      </p>
      <ul className="text-muted d-block mb-4">
        <li>
          -12 sati od uzimanja poslednjeg obroka treba da protekne pre dolaska u
          našu ambulantu i davanja uzorka krvi za analizu.
        </li>
        <li>-dan pre uzorkovanja izbegavati intenzivnu fizičku aktivnost</li>
        <li>-1 sat pre uzorkovanja krvi izbegavajte cigarete.</li>
      </ul>
      <p className="ml-2 mb-5 text-muted">
        Uzimanje uzorka krvi je preporučljivo ujutru, na prazan stomak, nakon
        što je proteklo 12 sati od uzimanja poslednjeg obroka, jer unos hrane
        dovodi po porasta koncentracije glukoze, holesterola, triglicerida,
        proteina, gvožđa... Pošto i terapija koju redovno koristite može uticati
        na verodostojnost rezultata, obavezno to naglasite našem medicinskom
        osoblju prilikom uzimanja uzoraka. To je posebno važno ako primate
        terapiju gvožđem, vitaminskim preparatima, hormonsku terapiju i dr.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Konzumiranje vode pre vađenja krvi nama olakšava posao. Ako je vaš
        organizam dobro hidriran, medicinskoj sestri biće lakše da pronađe venu,
        što vama garantuje bezbolniju intervanciju. Idealno bi bilo da, ako ste
        odrasla osoba, unesete oko dva litra vode dan-dva pre uzimanje uzorka
        krvi.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Kontrola krvnog pritiska
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Redovnom kontrolom krvnog pritiska smanjićete rizik od nastanka
        infrakta, pojavu moždanog udara, bolesti bubrega i drugih zdravstvenih
        problema. Visok krvni pritisak loše utiče na zdravlje jer uzrokuje
        oštećenje krvnih sudova i pojavu veoma ozbiljnih krdiovaskularnih
        bolesti.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Merenje šećera u krvi
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Redovna kontrola šećera u krvi od velikog je značaja za vaše zdravlje i
        njome možete da prevenirate nastanak dijabetesa. Kontrola se vrši više
        puta u toku dana i u skladu sa vašim potrebama i nalazima lekara.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Tumačenje rezultata
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Naši saradnici, lekari opšte medicine, pacijentu će protumačiti dobijene
        rezultate kao što su laboratorijska analiza krvi, laboratorijska analiza
        urina, ultrazvučni nalazi i drugo.
      </p>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      />

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default UzorkovanjeKrvi;
