import React, { useEffect } from "react";
// import { scrollTo } from "../components/scrollTo";

const KucnaNega = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // ref={scrollTo}
      className="container mb-5 p-3"
      style={{ lineHeight: "1.38rem" }}
    >
      <div className="d-flex flex-hc mb-5">
        <img
          style={{ maxWidth: "70px" }}
          src="../images/usluge/kucnanega.png"
        />
      </div>
      <h1
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        KUĆNA NEGA ZA VAS
      </h1>

      <h4 style={{ fontWeight: "bold" }} className=" text-primary">
        Nega bolesnih u kućnim uslovima lečenja
      </h4>
      <ul className="text-muted d-block mb-4">
        <li>•Davanje injekcija</li>
        <li>•Davanje infuzija</li>
        <li>
          •Zdravstvena nega nakon otpusta iz bolnice - postoperativna nega
        </li>
        <li>•Terapija rane</li>
        <li>•Parenteralna ishrana</li>
        <li>•Postoperativna rehabilitacija</li>
        <li>•Nega kolostoma i zamena diska stome</li>
        <li>•Nega i prevencija dekubita</li>
        <li>•Plasiranje ili zamena urinarnih katetera</li>
        <li>•Davanje klizme </li>
        <li>•Nabavka lekova i medicinskih pomagala</li>
        <li>•Sve vrste laboratorijskih analiza </li>
        <li>•Medicinska pomoć i pregledi saradnika</li>
        <li>•Sanitetski prevoz na teritoriji Srbije</li>
        <li>
          •Zagarantovana poverljivost podataka iz medicinske dokumentacije
        </li>
        <li>•Ubedljivo najbolji odnos cene i kvaliteta</li>
      </ul>

      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Nega nepokretnih lica
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Nepokretni bolesnici u kućnim uslovima zahtevaju posebnu brigu i pažnju
        svojih najbližih. Ukoliko se bolesnik otežano i ograničeno kreće ili je
        potpuno nepokretan, potrebna mu je posebna vrsta nege i pomoći. Članovi
        porodice to najčešće nisu u mogućnosti jer su odsutni zbog posla i
        nedovoljno stručni. Kako bi se nepokretnim osobama i njihovim najbližima
        olakšali svakodnevni život i funkcionisanje, mi iz ambulante Medicinska
        pomoc u Kragujevcu brinemo danonoćno o nepokretnim bolesnicima.
        Profesionalno, s pažnjom i posvećenošću omogućavamo da osobe čije je
        kretanje iz različitih razloga onemogućeno dobiju maksimalnu negu,
        podršku, pomoć i društvo. Korisnicima kojima je ograničeno kretanje kao
        posledica različitih bolesti, operacija ili povreda takođe pomažemo u
        svim vidovima funkcionisanja. Ukoliko pacijenti koriste invalidska
        kolica, hodalicu ili neko drugo ortopedsko sredstvo ili im je pomoć
        potrebna nakon što su doživeli duboku starosti, njihovo kretanje znatno
        je oslabljeno ili su u potpunosti vezani za krevet. U tom slučaju možete
        u potpunosti da se oslonite na naše profesionalne usluge i prepustite
        negu bolesnika našem timu. Brinemo o higijeni, hranjenju, redovnom
        uzimanju lekova, zameni urin-kesa, kontrolisanju krvnog pritiska i
        šećera u krvi, nabavci namirnica i lekova i svega što vam je potrebno.
        Organizujemo i specijalističkih preglede u vašem domu.
      </p>

      <p className="ml-2 mb-5 text-muted">
        Stručne, posvećene i ljubazne medicinske sestre, negovateljice i
        gerontodomaćice bave se negom nepokretnih bolesnika u kući na teritoriji
        grada i Šumadijskog okruga. Imamo toplu reč i osmeh za sve!
      </p>

      <h4 style={{ fontWeight: "bold" }} className=" text-primary">
        Kućna nega nepokretnih bolesnika uključuje:
      </h4>
      <ul className="text-muted d-block mb-4">
        <li>
          •pomoć oko higijene (kupanje nepokretnih bolesnika, pranje kose,
          zamena pelena, zamena urin-kesa...);
        </li>
        <li>•priprema i serviranje hrane, hranjenje;</li>
        <li>•kontrola, nabavka i briga o redovnom uzimanju lekova;</li>
        <li>•redovna kontrola pulsa, krvnog pritiska i šećera u krvi;</li>
        <li>•pomoć pri kretanju i korišćenju ortopedskih pomagala;</li>
        <li>•dežurstvo pored pacijenta i druženje;</li>
        <li>
          •sanitetski transport i pratnja medicinske sestre (odvođenje na
          lekarski pregled ili banjsko lečenje, na neko slavlje ili porodični
          događaj).
        </li>
      </ul>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      ></div>

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default KucnaNega;
