import React, { useEffect } from "react";
// import { scrollTo } from "../components/scrollTo";

const Dezurstvo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // ref={scrollTo}
      className="container mb-5 p-3"
      style={{ lineHeight: "1.38rem" }}
    >
      <div className="d-flex flex-hc mb-5">
        <img src="../images/usluge/dezurstvo.png" />
      </div>
      <h1
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        DEZURSTVO PORED PACIJENTA
      </h1>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Dežurstvo pored pacijenta 24 sata
      </h4>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        {" "}
        Palijativna nega bolesnika
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Potreba za zbrinjavanjem pacijenata putem vanbolničkog lečenja
        neprekidno raste. Živimo u okruženju gde nije neobično da pacijenti u
        određenom stadijumu bolesti ne dobijaju adekvatnu negu ili da za njih,
        nažalost, nema mesta u državnim ustanovama, pa su stoga upućeni na kućno
        lečenje. Tada, po pravilu, negu obavljaju članovi porodice, koji
        uglavnom nemaju dovoljno znanja, iskustva ni vremena da se posvete
        palijativnom pacijentu. U savremenom svetu postoji tendencija da se u
        kućnim uslovima poboljša kvalitet života pacijenta suočenog sa
        neizlečivom bolešću putem prevencije i lečenja svih simptoma bolesti. Tu
        se možete osloniti na nas…
      </p>
      <p className="ml-2 mb-5 text-muted">
        Medicinska pomoć Kragujevac nudi vam uslugu palijativne nege i
        zbrinjavanja svih pacijenata koji usled bolesti ili starosti ne mogu da
        brinu o sebi. Naše medicinske sestre i negovateljice dežuraće, u skladu
        sa vašim zahtevima, pored pacijentovog kreveta u 24- časovnom ritmu i
        prižiti mu svu negu i pomoć, brinući kako o njihovim fizičkim, tako i o
        psihosocijalnim i duhovnim potrebama. Naš tim profesionalaca ima za cilj
        da pomogne pacijentima s nemogućnošću normalnog funcionisanja u
        svakodnevnom životu.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Briga o teškim hroničnim bolesnicima nije nimalo jednostavna. Kada
        porodica zna da je pacijent u terminalnoj fazi bolesti i da su
        iscrpljene sve terapijske opcije, često se odlučuje da brigu o svom
        članu porodice poveri stručnim licima. Mnogi pacijenti zahtevaju
        celodnevnu negu u kućnim uslovima u cilju nadzora, održavanja higijene,
        olakšavanja simptoma, redovnog uzimanja terapije i slično. Medicinska
        pomoć Kragujevac ima specijalizovano osoblje koje, sem garantovane nege,
        može i aktivno da pruži podršku pozitivnim stavom, ulepša dan dobrim
        raspoloženjem, toplim rečima i razumevanjem.
      </p>
      <p className="ml-2 mb-5 text-muted">
        U ležeće pacijente ne spadaju samo oboleli od kancera, teških hroničnih
        bolesti, već sve više i oboleli od progresivnih plućnih i bubrežnih
        bolesti, autoimunih, degenerativnih bolesti i slično. Dežurstvo pored
        pacijenta služi da smanji neprijatnosti i posledice bolesti koje nastaju
        usled teških i neizlečivih stanja i obezbedi celoj porodici normalnu i
        neometanu svakodnevnicu. Kako bi se porodica pacijenta lakše nosila sa
        bolešću svog najmilijeg, Medicinska pomoć Kragujevac obezbediće vam
        stučan tim i dežurati pored kreveta pacijenta kako bi se prevazišle sve
        prepreke koje mu bolest nameće.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Ovaj sveobuhvatni zadatak počiva na saradnji porodice, nadležnih doktora
        i naših medicinskih sestara i negovateljica, na osnovu čega se određuje
        koje su medicinske usluge neophodne i da li su u pitanju privremene
        posete ili višesatna dnevna/noćna nega. Mi smo obučeni da vam pomognemo
        u svim fazama teške bolesti i zajedničkim snagama isplaniramo zaštitu i
        kontrolu određenog zdravstvenog stanja.
      </p>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      />

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default Dezurstvo;
