import React from "react";

const LoadingScreen = ({ loading }) => {
  const getClass = () => {
    return loading === true ? "loading" : "loading loadingOver";
  };

  return (
    <div className={getClass()}>
      {/* <p style={{ margin: "auto" }}>Loading</p> */}
      <div style={{ margin: "auto" }}>
        <img src="/images/svg/tail-spin.svg" style={{ height: "60px" }} />
      </div>
    </div>
  );
};

export default LoadingScreen;
