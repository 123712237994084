import React, { useEffect } from "react";
// import { scrollTo } from "../components/scrollTo";

const Previjanje = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // ref={scrollTo}
      className="container mb-5 p-3"
      style={{ lineHeight: "1.38rem" }}
    >
      <div className="d-flex flex-hc mb-5">
        <img
          style={{ maxWidth: "70px" }}
          src="../images/usluge/previjanje.png"
        />
      </div>
      <h1
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        PRIVATNO PREVIJANJE RANA KRAGUJEVAC
      </h1>

      <p className="ml-2 mb-5 text-muted">
        Medicinska pomoć Kragujevac u svakom trenutku vam je na raspolaganju za
        uslugu previjanja rana svih vrsta. Pored usluge previjanja rana u našoj
        ambulanti, i u kućnim posetama obezbedićemo vam stručni tretman u
        negovanju, posebno ukoliko je u pitanju ležeći bolesnik, koji mora imati
        svakodnevnu negu.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Svi imamo bar jedno iskustvo u saniranju rana u svakodnevnom životu.
        Pregled, čišćenje i zatvaranje rane predstavlja primarnu obradu rane, a
        ona se mora što pre sprovesti da ne bi došlo do sekundarnih infekcija,
        kada je rana zagađena mikroorganizmima i nečistoćama. Sve promene na
        rani medicinski obučena osoba će prepoznati i pravovremeno reagovati ako
        bilo šta odstupa od uobičajenog. Ako rana i pored svih tretmana postaje
        veća, gnoji se ili menja izgled, nađe stručno lice će uzeti bris i
        odneti ga u laboratoriju, a nakon dobijenih rezultata i odrediti
        terapiju. Adekvatna i redovna nega i previjanje rane sprečiće infekcije,
        koje mogu usporiti zarastanje rane i biti posebno opasne, pa čak i po
        život pacijenata. Tako naš medicinski tim može prepoznati simptome koji
        mogu dovesti do inficiranja rana i bolesniku pružiti svu potrebnu negu i
        time prevenirati nastanak bilo kakvih komplikacija, jer o vašem problemu
        brinemo svakodnevno. Ako je i pored svih preventivnih mera ipak došlo do
        neke infekcije, naša medicinska sestra će znati šta treba da radi kako
        bi se ona što pre izlečila. Neophodno je prepoznati o kojoj vrsti
        infekcije je reč i odmah pristupiti njenom saniranju. Dobro obučena i
        iskusna medicinska sestra Medicinske pomoći Kragujevac zna čime se
        inficirana rana ispira, kakvi oblozi se stavljaju i kad treba bolesnika
        uputiti lekaru. Ukoliko je rana ušivana, veoma je bitno prepoznati kako
        konci treba da izgledaju, koliko dugo treba da stoje i odrediti kad je
        vreme da se skidu.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Medicinska pomoć Kragujevac koristi i klasične tretmane, ali i savremene
        obloge za zarastanje rana, koje daju fenomenalne rezultate.
      </p>
      <p className="ml-2 mb-5 text-muted" />
      <h4 className=" text-primary">
        <strong>Vrste rana prema načinu nastanka:</strong>
      </h4>
      <ul className="text-muted d-block mb-4">
        <li>posekotina</li>
        <li>ujed</li>
        <li>tačkasta rana</li>
        <li>oguljotina.</li>
      </ul>
      <h4 className=" text-primary">
        <strong>Vrste rana prema stanju kože:</strong>
      </h4>
      <ul className="text-muted d-block mb-4">
        <li>zatvorena</li>
        <li>otvorena</li>
        <li>probojna</li>
        <li>potkožna.</li>
      </ul>

      <p className="ml-2 mb-5 text-muted">
        Nekada se rane nalaze na teže dostupnim mestima, pogotovo ukoliko je reč
        o ranama nastalim od ležanja. Da bi se takva rana previla, potrebno je
        pomerati bolesnika u tačno određeno vreme, što je često nezgodno i
        teško, a bolesniku bolno. Naše znanja i iskustvo u ovoj oblasti pomoći
        će bolesniku da se pomera bezbedno, lako i bezbolno. Bolesnik s ranom,
        posebno ako je ona hronična ili preti da to postane, zahteva adekvatan
        pristup osoba oko njega. Ukoliko je to vaš zahtev da ukućani brinu o
        bolesniku, od našeg medicinskog tima dobićete osnovne smernice o
        njegovoj nezi i stručnu pomoć u tretiranju rana, ali i o opštem stanju
        pacijenta: kako da ga pomerate, kako da ga ohrabrite da istraje u borbi
        i sve što vam je potrebno u novonastaloj situaciji.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Ukoliko je u pitanju hronična rana, ona se tretira posebno i sa više
        medicinskih aspekata, zahteva holistički i multidisciplinarni pristup.
        Sve hronične rane naseljene su raznim sojevima bakterijama, pa se
        preporučuje uzimanje brisa. Hroničnim ranama smatraju se one rane koje
        ne zarastaju u predviđenom vremenskom periodu za tip i lokalizaciju
        rane, a proces zarastanja rane traje duže od šest nedelja. Smanjenje
        veličine rane za više od 40% tokom 2-3 nedelje lečenja ukazuje na
        adekvatan rast tkiva i kontrakciju rane. Zdravo granulaciono tkivo je
        rozikaste boje, podseća na meso lososa, čvrsto je i vlažno i indikator
        je dobrog zarastanja. Nezdravo granulaciono tkivo je tamnocrvene boje,
        lako krvari na dodir, prisutni su brojni džepovi i mostovi s fibrinom u
        rani, rana je bolna na dodir. Može biti neprijatnog mirisa zbog
        prisustva infekcije ili nekrotičnog tkiva. Svaka infekcija usporava
        zarastanje rane i može ugroziti život pacijenta ukoliko dođe do sepse.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Hronične rane mogu se tretirati sistemskim antibioticima,
        kortikosteroidima, emolijensima, cink-oksid pastama, pri čemu treba
        izbegavati parfimisane proizvode. Inficirane rane tretiraju se hidrogen-
        peroksidom (H2O2) dok postoji gnojna sekrecija, ispiraju se fiziološkim
        rastvorom i stavljaju se suportivni oblozi, zatim aktivni oblozi sa
        srebrom ili ugljem itd.
      </p>

      <h4 className="mb-2 text-primary">
        <strong>DEKUBITALNE RANE</strong>
      </h4>
      <p className="ml-2 mb-5 text-muted">
        DEKUBITALNE RANE su najfrekventniji tip rana koje karakterišu crvenilo
        iznad zone pritiska, oteklina, induracija, stvaranje mehurića i
        ekskorijacije, ulkus sa vidljivim potkožnim tkivom, ulkus do mišićne
        fascije, kombinacija nekroze kože, masnog tkiva i mišića, inficirane
        koštane strukture… Prevencija je od presudne važnosti da do fatalnih
        rana uopšte i ne dođe. Zato je neophodno često menjanje položaja tela
        kada se uoči prekid cirkulacije, koji dovodi do odumiranja tkiva, jer na
        taj način može se izbeći opasnosti od dekubitusa. Mesta na koja posebno
        treba paziti prilikom sedenja i ležanja jesu izbočeni delovi tela:
        sedalni deo, kukovi i pete.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Lečenje dekubitusa je veoma složen i dug proces koji zahteva
        multidisciplinaran pristup i angažovanje doktora različitih
        specijalnosti: dermatologa, hirurga, interniste (kardiologa,
        endokrinologa), urologa, neurologa, fizijatra, srednjemedicinskog kadra
        i pomoćnog medicinskog osoblja. Veoma važnu ulogu u lečenju obolelih od
        dekubitusa ima medicinska sestra, koja bolesniku pruža svu potrebnu
        njegu i sprovodi sve mere prevencije. Zato lečenje dekubitusa spada u
        kompleksna, često i najskuplja lečenja u medicini, ujedno i
        najdugotrajnija.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Postoje konzervativno i hirurško lečenje dekubitusa. Konzervativno
        lečenje obuhvata čestu preraspodelu pritiska na kritičnim područjima
        podmetanjem jastučića, primenu zavoja na ugroženim mestima, upotrebu
        specijalnih antidekubitalnih dušeka i promenu položaja bolesnika na
        svaka dva sata. Od presudnog značaja je svakodnevna higijena tela,
        redovno menjanje posteljine i briga o naborima na posteljini i donjem
        vešu. Od velike pomoći je i redovna i pravilna ishrana, gde treba da se
        unosi dovoljna količina belančevina, vitamina (posebno vitamina A i C),
        cink, gvožđe, bakar, kalcijum... Naša Medicinska pomoć Kragujevac pomoći
        će vam u stalnoj kontroli infekcije, redovnoj dezinfekciji rane
        antiseptikom, redovnoj upotrebi lekova i antibiotika prema antibiogramu,
        u primeni različitih obloga i lokalnih lekova, elektromagnetnoj
        terapiji, UZ terapiji...
      </p>
      <p className="ml-2 mb-5 text-muted">
        Kako hirurško lečenje podrazumeva radikalno odstranjenje odumrlog tkiva,
        uklanjanje upalno promenjenih delova kosti i zatvaranja defekta lokalnim
        kožnim ili mišićnim režnjevima, vaš najbolji lek je redovna i dugotrajna
        nega i terapija uz pomoć našeg stručnog i obučenog tima. Zajedničkim
        snagama i trudom možemo drastično unaprediti i poboljšati vaš problem s
        ranama i izbeći širok dijapazon komplikacija koje mogu i ugroziti vaš
        život. Medicinska pomoć Kragujevac u skladu s vašim potrebama i po
        dogovoru odgovoriće na svaki vaš zahtev.
      </p>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      />

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default Previjanje;
