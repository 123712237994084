import { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Footer from "./components/footer";
import Navbar from "./components/navbar";
import Home from "./screens/home";
import Contact from "./screens/contact";
import Infuzija from "./serviceScreens/infuzija";
import Injekcija from "./serviceScreens/injekcije";
import Previjanje from "./serviceScreens/previjanje";
import NegaStome from "./serviceScreens/negastome";
import KucnaNega from "./serviceScreens/kucnaNega";
import Klizma from "./serviceScreens/klizma";
import ZamenaKatetera from "./serviceScreens/zamenaKatetera";
import UzorkovanjeKrvi from "./serviceScreens/uzorkovanjeKrvi";
import Dezurstvo from "./serviceScreens/dezurstvo";
import PomocUKuci from "./serviceScreens/pomocukuci";
import IspiranjeUsiju from "./serviceScreens/ispiranjeUsiju";
import IspiranjeKatetera from "./serviceScreens/ispiranjeKatetera";
import Sanitet from "./serviceScreens/sanitet";
import LoadingScreen from "./components/LoadingScreen";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 0);
  }, []);
  return (
    <>
      {<LoadingScreen loading={loading} />}

      <Navbar />
      <Switch>
        {/* Usluge */}
        <Route path="/infuzija" component={Infuzija} />
        <Route path="/injekcija" component={Injekcija} />
        <Route path="/previjanje" component={Previjanje} />
        <Route path="/negastome" component={NegaStome} />
        <Route path="/kucnanega" component={KucnaNega} />
        <Route path="/klizma" component={Klizma} />
        <Route path="/zamenakatetera" component={ZamenaKatetera} />
        <Route path="/uzorkovanjekrvi" component={UzorkovanjeKrvi} />
        <Route path="/dezurstvoporedpacijenta" component={Dezurstvo} />
        <Route path="/pomocukuci" component={PomocUKuci} />
        <Route path="/sanitet" component={Sanitet} />
        <Route path="/ispiranjeusiju" component={IspiranjeUsiju} />
        <Route path="/ispiranjekatetera" component={IspiranjeKatetera} />
        {/* Pocetna i kontakt */}
        <Route path="/kontakt" component={Contact} />
        <Route path="/pocetna" component={Home} />
        <Redirect from="/" to="/pocetna" />
        <Route path="/" component={Home} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
