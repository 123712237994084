import React, { useEffect } from "react";
// import { scrollTo } from "../components/scrollTo";

const IspiranjeUsiju = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // ref={scrollTo}
      className="container mb-5 p-3"
      style={{ lineHeight: "1.38rem" }}
    >
      <div className="d-flex flex-hc mb-5">
        <img
          style={{ maxWidth: "70px" }}
          src="../images/usluge/ispiranjeUsiju.svg"
        />
      </div>
      <h1
        className="text-center mb-6 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        ISPIRANJE UHA
      </h1>
      <p className="ml-2 mb-5 text-muted">
        Shodno učestalosti neprijatnog osećaja gluhosti i zapušenosti uha u
        opštoj populaciji svih kategorija i uzrasta, Medicinska pomoć Kragujevac
        obavlja ispiranje ušiju i u vašem domu i u našoj ambulanti. Ispiranje
        ušiju predstavlja intervenciju kojom se sa tečnošću ispira sadržaj
        spoljašnjeg uva i primenjuje se ukoliko pacijent nije imao
        specijalističke intervencije koje će zahtevati odstranjivanje sadržaja
        iz uva na drugi način.{" "}
      </p>
      <p className="ml-2 mb-5 text-muted">
        Problemi sa ušima veoma su česti jer su ušni kanali u svakodnevnom
        funkcionisanju izloženi vazduhu, vodi, isparenjima, sitnim predmetima… S
        druge strane, ljudske uši su organ koji se sam čisti. Tokom žvakanja,
        kijanja, kašljanja naslage masti nastale u ušima se uglavnom same
        eliminišu. Međutim, dešava se i da se naslage pretvore u takozvane
        čepove, kada se uši “začepe”. Prisustvo vode u uhu pokreće taloženje
        ušne masti, takozvani cerumen, pa na letovanjima neretko dolazi do
        zaglušenja uha, kao i osećaja da se voda neprestano nalazi u uhu. Nikako
        ne pokušavajte sami da otklonite taj osećaj, jer postoji opasnost da
        oštetite ušni kanal, a time problem zakomplikujete nastankom otoka i
        vrlo jakog bola u uhu.{" "}
      </p>
      <p className="ml-2 mb-5 text-muted">
        Ispiranje ušiju pre odlaska na more je preventivna mera. Problemi sa
        ušima su uobičajeni na letovanju, kada se iznenada javljaju bol, upale,
        začepljenja. Ako se ikada vama ili vašem detetu desilo da se usled
        plivanja i ronjenja na moru, jezeru, bazenu ili u reci javi osećaj
        zaglušenosti uha, praćen bolom i oticanjem, ORL specijalisti savetuju da
        se pre odlaska na letovanje obavi pregled uha, pre nego što nastane
        ozbiljan problem. U tom cilju nudimo vam pregled otoskopom i ispiranje
        ušiju. Naše obučeno medicinsko osoblje obaviće preventivno ispiranje uha
        kako bi se komplikacije predupredile. Tokom procedure ispiranja otklanja
        se nataloženi cerumen i eventualne nečistoće iz uha, što dovodi do
        oslobađanja ušnih kanala i eliminisanja simptoma. Da biste saznali više
        o ovoj proceduri i bezbrižno otputovali na letovanje, pozovite dežutne
        telefone naše Medicinske pomoći Kragujevac.{" "}
      </p>
      <p className="ml-2 mb-5 text-muted">
        Ne dozvolite da se na letovanju mučite sa bolom, neprijatnostima,
        osećajem zaglušenja, otokom, posebno ne ako se opredelite za letovanje u
        inostranstvu. Mnogo je jednostavnije, jeftinije, sigurnije i brže da
        predupredite upalu uha i uživate u vodi sa celom porodicom.{" "}
      </p>
      <p className="ml-2 mb-5 text-muted">
        Ukoliko je već došlo do težih oblika obolevanja i imate upalu srednjeg
        uha, začepljenje ušnog kanala, poremećaj sluha ili neprijatno zujanje u
        ušima, neophodno je uraditi pregled otoskopom, aparatom koji omogućava
        lekaru da vidi bubnu opnu i postavi dijagnozu. Spisak mogućih problema
        sa ušima je poveći, a na njihovo rešavanje reagujte odmah jer mogu
        ozbiljno ugroziti sluh. Ispiranje cerumena uva (otklanjanje cerumenskih
        čepova) bezbolnom metodom pruža vam Medicinska pomoć Kragujevac.{" "}
      </p>
      <p className="ml-2 mb-5 text-muted">
        Najjednostavnije i najpristupačnije sredstvo za ispiranje ušiju i
        uklanjanje čepova je rastvor vodonika peroksida (hidrogen). Hidrogen
        odlično savladava problem sa naslagama u ušima. Preporučuje se da ga
        primenite čim osetite neprijatan osećaj začepljenosti ušiju, a to možete
        prepustiti sigurnim rukama našeg tima u našoj ambilanti ili, ako vam je
        udobnije, izaći ćemo na teren i doći do vas. Pacijent treba da nagne
        glavu ili da legne, a nakon postupka ispiranja ušiju ostaće u tom
        položaju oko pet minuta. Karakteristično šuštanje u uhu znači da je
        došlo do reakcije, nakon čega čep počinje da omekšava.{" "}
      </p>
      <p className="ml-2 mb-5 text-muted">
        Dokle god imate simptome zapušenosti uha i tokom procedure ispiranja
        ušiju, nikako nemojte koristiti štapiće za uši. Nismo ni svesni koliko
        možemo sebi naškoditi.{" "}
      </p>
      <p className="ml-2 mb-5 text-muted">
        Postoje određena stanja u kojima nije poželjno ispiranje ušiju, već je
        potrebno izvršiti takozvanu aspiraciju, odnosno usisavanje sadržaja
        spoljašnjeg uva. Zato naglasite našoj medicinskoj sestri ukoliko imate
        otvor na bubnoj opni, aeracione cevčice ili je sprovedena bilo kakva
        hirurška intervencija na uvu.
      </p>

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default IspiranjeUsiju;
