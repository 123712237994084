import React, { useEffect } from "react";
// import { scrollTo } from "../components/scrollTo";

const ZamenaKatetera = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      // ref={scrollTo}
      className="container mb-5 p-3"
      style={{ lineHeight: "1.38rem" }}
    >
      <div className="d-flex flex-hc mb-5">
        <img style={{ maxWidth: "70px" }} src="../images/usluge/kateter.png" />
      </div>
      <h1
        className="text-center mb-5 text-primary-black"
        style={{ lineHeight: "normal" }}
      >
        PLASIRANJE I ZAMENA URINARNIH KATETERA
      </h1>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Plasiranje i zamena katetera pacijenta
      </h4>
      <p className="ml-2  text-muted">
        Ako dodete u situaciju da morate da nosite urinarni kateter, možda ćete
        se groziti te ideje i paničiti, ali naše stručno medicinsko osoblje će
        vam odagnati svaki strah, postupno objasniti proceduru jer je u
        obostranom interesu da intervencija bude bezbolna i da se osećate
        sigurno. Kateter se najčešće plasira pre operacije, ali postoje i
        sledeće indikacije za kateterizaciju mokraćne bešike:
      </p>
      <ul className="text-muted d-block mb-4">
        <li>-Zastoj urina (retencija urina)</li>
        <li>-Bezvoljno mokrenje (inkontinencija)</li>
        <li>-Dijagnostičke i terapijske svrhe</li>
      </ul>
      <h4 style={{ fontWeight: "bold" }} className="text-primary">
        Potreban materijal za kateterizaciju:
      </h4>
      <ul className="text-muted d-block mb-4">
        <li>- Sterilan kateter</li>
        <li>- Urinarna kesa</li>
        <li>- Sterilne rukavice</li>
        <li>- Dezinfekciono sredstvo</li>
        <li>- Špricevi</li>
        <li>- Aqua destilata</li>
        <li>- Glicerinsko ulje</li>
        <li>- Lidocain</li>
      </ul>
      <p className="ml-2 mb-5 text-muted">
        Plasiranje i zamenu urinarnog katetera, što podrazumeva uklanjanje
        starog katetera i postavljanje novog katetera, kao i ispiranje mokraćne
        bešike, obavljamo i u vašem domu i u našoj ambulanti. Plasiranje
        (uvodenje) katetera, odnosno kateterizacija, predstavlja uvlačenje
        katetera u neki kanal u dijagnostičke ili terapeutske svrhe radi širenja
        kanala (dilataciona kateterizacija) i pražnjenja sadržaja iz nekog
        organa (evakuaciona kateterizacija). Plasiranje katetera u mokraćnu
        bešiku je medicinsko-tehnička radnja koja mora da se izvodi uz strogo
        poštovanje aseptičnih uslova za rad.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Koje vrste katetera postoje?
      </h4>
      <p className="ml-2 mb-5 text-muted">
        Trajni, stalni (permanentni) kateteri, sa balonom, mogu biti dvokraki i
        trokraki. Danas je najčešće u upotrebi Folijev (Foley) stalni dvokraki
        kateter. Izvodni kraci služe za odvođenje mokraće i za naduvavanje
        balona koji sprečava izvlačenje samog katetera. Mogu ostati plasirani i
        do 12 nedelja. Prave se od sve tri vrste materijala.
      </p>
      <p className="ml-2 mb-5 text-muted">
        Privremeni (intermitentni) bez balona, takozvani pravi kateter stoji 3
        do maksimalno 7 dana, napravljen je od lateksa i PVC-a.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Izvođenje kateterizacije kod muškaraca
      </h4>
      <p className="ml-2 mb-6 text-muted">
        Potrebno je da pacijent leži na leđima u horizontalnom položaju sa
        ispruženim nogama. Ispod bolesnika se postavi mušema sa kompresom. Trbuh
        i grudi pokriju se čaršafom. Medicinska sestra navlači rukavice, podigne
        penis i drži između trećeg i četvrtog prsta, a prepucijum se prevuče
        preko glansa kažiprstom i palcem. Očisti se i dezinfikuje 2-3 puta vrh
        glansa i spoljašnje ušće uretre tupferima gaze natopljene blagim
        dezinfekcionim sredstvom. Kažiprstom i palcem se uhvati kateter (pomoću
        sterilne anatomske pincete ili peana), a drugi kraj katetera hvata se
        četvrtim i petim prstom. Pre uvođenja katetera na otvor mokraćne cevi
        može da se stavi kap anastezionog gela i pričeka nekoliko minuta. Vrh
        katetera prelije se sterilnim glicerinom i veoma oprezno kateter uvodi u
        spoljašni otvor mokraćnog kanala. Kateter se uvlačiti polako, laganim
        pokretima ruku, jer se nasilnim uvlačenjem može povrediti uretra. Kad se
        oseti otpor sfinktera, penis se postavi u horizontalni položaj, a kad
        krene mokraća, znači da je kateter u mokraćnoj bešici. Ukoliko nema
        mokraće, kateter treba povući malo unazad jer postoji mogućnost da otvor
        upire u zid mokraćne bešike. Ako i posle toga nema mokraće, onda je
        mokraćna bešika prazna.
      </p>
      <h4 style={{ fontWeight: "bold" }} className="mb-2 text-primary">
        Izvođenje kateterizacije kod muškaraca
      </h4>
      <p className="ml-2 mb-6 text-muted">
        Pacijentkinja se postavlja u ginekološki položaj i obavi se pranje
        polno-analnog predela. Navuku se sterilne rukavice i prstima se sa dva
        tupfera rašire male i velike polne usne, očisti i dezinfekuje otvor
        uretre brisanjem natopljenim blagim dezinfekcionim sredstvom. Zatim se
        kateter kroz otvor uretre uvodi u mokraćnu bešiku. Na 4-5 cm oseti se
        otpor, a zatim se ulazi u slobodni prostor, što znači da se kateter
        nalazi u bešici. Ukoliko se mokraća uzima za urinokulturu, prvi mlaz
        treba ispustiti u bubrežnjak, zatim napuniti sterilnu posudu, zatvoriti
        je i sa uputom poslati u mikrobiološku laboratoriju.
      </p>

      <div
        className="d-block w-80 m-center bg-primary-gradient mb-7"
        style={{ height: "5px" }}
      />

      <p className="ml-2 mb-3 text-muted">
        Budite odgovorni prema svom zdravlju!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Redovno kontrolišite krv, poradite na prevenciji, pravovremeno se
        zaštitite i poboljšajte svoje zdravlje. Ne dozvolite da se pojave prvi
        simptomi i bolest uznapreduje. Pomoći ćemo vam u prevenciji svih
        bolesti, ali i u dijagnostici i lečenju postojećih.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Analiziramo porodičnu istoriju bolesti i druge faktore rizika za
        obolevanje, preporučujemo korekciju ishrane i uvođenje nutritivno
        bogatih obroka, pratimo evropska dostignuća u svim oblastima medicine i
        specijalističkih grana i držimo visok standard u pružanju usluga.{" "}
      </p>
      <p className="ml-2 mb-3 text-muted">
        Naše višedecenijsko iskustvo, bezbednost i vrhunska stručnost u pružanju
        zdravstvenih usluga i nege u vašem domu bazične su preporuke naše
        medicinske ambulante. Sve je građeno na velikom međusobnom poverenju jer
        naš tim postaje deo vaše porodice!
      </p>
      <p className="ml-2 mb-3 text-muted">
        Od nas ćete uvek dobiti stručne savete u vezi s negom i lečenjem
        pacijenata svih generacija i zdravljem celokupne porodice, a ukoliko vam
        je potrebna psihološka podrška, konsultovaćemo se s našim vrhunskim
        psiholozima i psihijatrima i uneti malo pozitivnog duha u vaš život!
      </p>
      <p className="ml-2 mb-3 text-muted">VAŠA MEDICINSKA POMOĆ KRAGUJEVAC</p>
      <p className="ml-2 mb-3 text-muted">
        Ulica potporučnika Govedarice 12, Kragujevac
      </p>
      <p className="ml-2 mb-3 text-muted">
        U neposrednoj blizini Kliničkog centra Kragujevac
      </p>
    </div>
  );
};

export default ZamenaKatetera;
